
import { Component, Vue, Provide } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'
import configs from '@/configs'
import { getLiangChuAuth } from '@/utils/cookies'

@Component({
  name: 'Liangchu',
  mixins: [GlobalMixin]
})
export default class LiangchuPage extends Vue {
  async created () {
    const code = getCurrentQuery('code') || ''
    if (this.isWeixin()) { // 如果在微信内
      if (!code) { // 没有code去www拿code
        console.log('liangchu')
        const wxAuthUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=APP_ID&redirect_uri=REDIRECT_URI&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect'
        const www = location.href.indexOf('pre-passport.benlai.com') > -1 ? 'testw01qa.benlai.com/passportbenlai' + location.pathname : 'www.benlai.com/passportbenlai' + location.pathname
        const isTest = !!getCurrentQuery('env')
        const appId = isTest ? 'wxae80bdca2f17b6bb' : 'wxfd61e8705acbac29'
        location.href = wxAuthUrl
          .replace('APP_ID', appId)
          .replace('REDIRECT_URI', encodeURIComponent(`https://${www}`))
      } else { // code已经存在
        try {
          // 把code给接口
          const res = await httpHelper.post({
            url: 'authorize/liangchuDomain',
            contentType: 'application/x-www-form-urlencoded',
            data: {
              weChatCode: code
            },
            headers: {
              Authorization: configs.basicAuthorization
            }
          })
          console.log('liangchu', res)
          location.replace(res.value.extend.gotoUrl)
        } catch (error) {
          this.goTo(error, 4, 20)
        }
      }
    } else {
      if (getLiangChuAuth()) {
        this.codeExchangeUrl('1exB')
        return
      }
      location.replace('/sms?smsType=4&grantType=20')
    }
  }
}
